<template>
  <section class="py-6 py-lg-8" ref="breif">
    <div class="container">
      <div class="px-lg-8">
        <p class="mb-5">
          仁大資訊成立於1999年，為台灣資訊服務產業龍頭廠商，致力於提供客戶一站式全方位資訊服務，我們提供資訊軟硬體銷售、維運與IT全委外服務，客戶群從原本深耕的金融、電信產業到近年來擴及製造、醫療與流通產業，我們以成為最值得客戶託付的資訊服務廠商為己任，不斷創新服務商業模式。
        </p>
        <p class="mb-3 fw-bold">
          全台14個自有服務據點，同業最多，服務全省性多據點客戶能力最佳。<br />
          全台自有工程師超過350位，維運量能最大。<br />
          客戶群均為各產業龍頭企業，值得信賴。<br />
          通過ISO9001品質認證與ISO27001資安認證。
        </p>
        <div class="row mb-5">
          <div class="col-5 col-sm-4 col-md-3 col-lg-2">
            <img src="@/assets/image/iso/logo-iso27001.jpeg" alt="iso27001" />
          </div>
          <div class="col-5 col-sm-4 col-md-3 col-lg-2">
            <img src="@/assets/image/iso/logo-iso9001.jpeg" alt="iso9001" />
          </div>
        </div>
        <p>
          仁大資訊本著專業、承諾、效率的核心價值，專注資訊服務本業，提供客戶最滿意的服務並創造利害關係人最大利益，在ESG浪潮下，我們與客戶共同努力落實執行ESG的各項指標，共創永續未來。
        </p>
      </div>
    </div>
  </section>

  <!-- 沿革與獎章 -->
  <section class="py-6 py-lg-8 bg-gray-100" ref="win">
    <div class="container">
      <div class="px-lg-8">
        <h2 class="content-title fs-1 text-center mb-8">沿革與獎章</h2>
        <ul class="row algin-items-center">
          <li class="col cursor-pointer" v-for="y in years" :key="y" @click="currentView = y">
            <p
              class="fs-9 fs-lg-7 p-2 text-center text-white bg-secondary fw-normal"
              :class="{ 'square-arrow--bottom': currentView === y }"
            >
              {{ y }}
            </p>
          </li>
        </ul>
        <ul class="py-3 py-lg-7">
          <li
            v-for="event in eventContent"
            :key="event.year"
            class="d-lg-flex align-items-center border-bottom py-3 py-lg-5 px-5 px-lg-0"
          >
            <h6 class="w-10 text-primary fw-light mb-1 mb-lg-0">{{ event.year }}</h6>
            <ul>
              <li
                v-for="desc in event.descript"
                :key="desc"
                class="fs-7 mb-1 l-style-lg-disc w-100"
              >
                {{ desc }}
              </li>
            </ul>
          </li>
        </ul>
        <ul class="row d-none d-lg-flex">
          <li class="col cursor-pointer" v-for="y in years" :key="y" @click="currentView = y">
            <p
              class="fs-7 p-2 text-center text-white bg-secondary fw-normal"
              :class="{ 'square-arrow--top': currentView === y }"
            >
              {{ y }}
            </p>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <!-- 企業認證 -->
  <section class="py-6 py-lg-8" ref="guarantee">
    <div class="container">
      <div class="px-lg-8">
        <h2 class="content-title fs-1 text-center mb-8">企業認證</h2>
        <div class="row justify-content-center">
          <div class="col-md-6">
            <div class="shadow-sm border border-gray-100 p-3">
              <img src="@/assets/image/iso/ISO27001-2022.jpg" alt="ISO27001:2022" />
            </div>
          </div>
          <div class="col-md-6">
            <div class="shadow-sm border border-gray-100 p-3">
              <img src="@/assets/image/iso/ISO9001.jpeg" alt="ISO9001:2015" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      currentView: "2020年 - 2024年",
      events: [
        {
          year: "1999年",
          contents: [
            {
              year: "1999",
              descript: ["榮獲HP NetServer產品風雲業務績優獎。"],
            },
          ],
        },
        {
          year: "2000年 - 2009年",
          contents: [
            {
              year: "2009",
              descript: [
                "Websense best contibution partner.",
                "整體營收突破六億元。",
                "榮獲台灣地區大型企業排名TOP5000。",
                "榮獲HP上半年度商用產品-業績貢獻獎。",
                "榮獲Lenovo最佳加值經銷商。",
              ],
            },
            {
              year: "2008",
              descript: [
                "資本額為一億元整，員工人數突破300人",
                "Websense best contibution partner.",
                "榮獲HP NAS Club Winner.",
                "榮獲HP上半年度商用產品-金銷獎。",
              ],
            },
            {
              year: "2007",
              descript: [
                "於4月成立上海營運分公司，前進大陸市場。",
                "榮獲Lenovo年度最佳商用產品經銷商。",
              ],
            },
            {
              year: "2006",
              descript: [
                "資本額為8900萬，員工人數破250人大關。",
                "榮獲IBM加值經銷夥伴楷模-刀鋒戰士。",
                "榮獲HP網路儲存解決方案事業處卓越經夥伴貢獻獎。",
              ],
            },
            {
              year: "2005",
              descript: [
                "EPS = 3.34，資本額為7400萬，員工人數破200大關。",
                "榮獲IBM加值經銷夥伴楷模。",
                "榮獲Microsoft年度優良合作夥伴表揚。",
                "榮獲HP年度商用筆記型電腦-業績最佳貢獻獎。",
                "榮獲HP Top Industry Standard Servers Partner - Servers & Storage Certified Reseller Achievers Award 2005.",
              ],
            },
            {
              year: "2004",
              descript: [
                "擁有150位優秀員工、健全的福利制度。",
                "榮獲趨勢科技認證中小企業加值服務經銷商。",
                "榮獲IBM Server xSeries 最佳黃金經銷商。",
              ],
            },
            {
              year: "2003",
              descript: [
                "仁大EPS = 3.04(其它同業約為0.37)，增資為5600萬，購入商業大樓。",
                "榮獲第三季三項全能獎 - 完成第三季PSG、IPG、ESG業績目標。",
                "榮獲HP第一屆金銷獎, PSG最佳貢獻獎。",
              ],
            },
            {
              year: "2002",
              descript: ["榮獲PC特殊成就獎-林士茵。"],
            },
            {
              year: "2001",
              descript: ["上市公司加入仁大法人股東行列。"],
            },
            {
              year: "2000",
              descript: [
                "營收超過2億元以上。(當時資本額3200萬，EPS = 1.06)",
                "榮獲惠普科技2000 Golden VAR黃金經銷商。",
              ],
            },
          ],
        },
        {
          year: "2010年 - 2019年",
          contents: [
            {
              year: "2019",
              descript: ["榮獲Lenovo 2019 PC最佳商用經銷商", "長庚Face++人臉辨識專案部署。"],
            },
            {
              year: "2018",
              descript: ["正式成為 ABSP (Apple Business Solution Provider)的授權廠商"],
            },
            {
              year: "2017",
              descript: [
                "Code Go虛擬票證平台，榮獲第14屆國家品牌玉山獎-最佳產品類 首獎",
                "榮獲第14屆國家品牌玉山獎-傑出企業類 首獎",
                "榮獲Lenovo PC最佳商用經銷商",
                "遠傳推行優良供裝服務品質, 協助服務完成SGS QualiCert認證。",
                "榮獲台灣地區大型企業排名TOP 5000。",
                "榮獲第14屆國家品牌玉山獎，類別：傑出企業類。",
                "Code Go虛擬票證平台，榮獲第14屆國家品牌玉山獎，類別：最佳產品類。",
              ],
            },
            {
              year: "2016",
              descript: [
                "引進多款實體機器人開發應用。",
                "榮獲台灣地區大型企業排名TOP5000。",
                "榮獲DELL年度最佳業績成長獎。",
                "榮獲Lenovo Server最佳貢獻經銷商。",
                "榮獲HP傑出銷售獎。",
                "榮獲Hewlett Packard Enterprise年度最佳合作夥伴獎。",
              ],
            },
            {
              year: "2015",
              descript: [
                "榮獲HP經銷商最佳貢獻獎。",
                "榮獲Lenovo Data Center Partner數據中心合作夥伴。(年度不詳)",
                "榮獲Lenovo PC最佳商用經銷商。",
              ],
            },
            {
              year: "2014",
              descript: [
                "台灣地區現有員工已超過500人。",
                "投入人工智慧（AI）之研究領域，並成立機器人事業群。",
                "榮獲Lenovo PC最佳商用經銷商。",
                "榮獲HP卓越金級經銷夥伴 - 年度最佳業績貢獻獎。",
              ],
            },
            {
              year: "2013",
              descript: [
                "榮獲台灣地區大型企業排名TOP 5000。",
                "榮獲HP Printing & Computing Services Specialization, 2013-2014年度首選合作夥伴，最佳合作夥伴。",
              ],
            },
            {
              year: "2012",
              descript: ["榮獲台灣地區大型企業排名TOP 5000。", "榮獲HP惠普科技最佳顧客滿意度獎。"],
            },
            {
              year: "2011",
              descript: [
                "榮獲台灣地區大型企業排名TOP 5000。",
                "榮獲中華民國第八屆國家品牌玉山獎傑出企業類獎項。",
              ],
            },
            {
              year: "2010",
              descript: [
                "台灣地區已擁有14個服務據點，且有建置完整的備品、零件倉及物流車隊，透過統一的管理平台進行服務調度。",
                "榮獲台灣地區大型企業排名TOP5000。",
                "榮獲Lenovo最佳加值經銷商。",
              ],
            },
          ],
        },
        {
          year: "2020年 - 2024年",
          contents: [
            {
              year: "2024",
              descript: ["榮登台灣地區大型企業排名TOP 5000"],
            },
            {
              year: "2023",
              descript: [
                "本公司資訊服務整合系統(HSIS)於2023年12月18日通過國際最新版及最高資安標準 ISO27001：2022之驗證",
              ],
            },
            {
              year: "2022",
              descript: ["榮登台灣地區大型企業排名TOP 5000"],
            },
            {
              year: "2021",
              descript: [
                "於2021年10月27日榮獲ISO 9001:2015品質管理系統驗證",
                "於2021年12月3日取得RBA負責任商業聯盟行為準則審核驗證",
              ],
            },
            {
              year: "2020",
              descript: [
                "榮獲遠傳企業社會責任評鑑獎",
                "榮獲遠傳攜手永續先鋒隊-最佳夥伴獎",
                "捐助中山大學西灣圓夢助學金，榮獲中山大學感謝狀",
                "於2020年12月18日取得ISO27001：2013資訊安全管理系統驗證",
              ],
            },
          ],
        },
      ],
    };
  },
  computed: {
    years() {
      return this.events.map((e) => e.year);
    },
    eventContent() {
      return this.events.find((e) => e.year == this.currentView).contents;
    },
    anchor() {
      return this.$route.query.anchor;
    },
  },
  methods: {
    scrollToRef(refName) {
      try {
        let element = this.$refs[refName];
        let top = element.offsetTop + 480;
        setTimeout(() => {
          window.scrollTo(0, top);
        }, 0);
      } catch (error) {
        // regardless of
      }
    },
  },

  watch: {
    anchor(val) {
      this.scrollToRef(val);
    },
  },
  mounted() {
    this.scrollToRef(this.anchor);
  },
};
</script>
